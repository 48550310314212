import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import FileList from "./FileList";
import FileUpload from "./FileUpload";
import {WebGlDisplay, ViewControlProps, FileLoadProps, viewControlDefaultCentre, viewControlDefaulSkyLightIntensity, viewControlDefaultDrawFOVHelper, viewControlDefaultDrawLightHelpers, viewControlDefaultEyeLightIntensity, viewControlDefaultEyeLightPosition, viewControlDefaultFar, viewControlDefaultFov, viewControlDefaultNear, viewControlDefaultPosition, viewControlDefaultSkyLightPosition, viewControlDefaultUp, viewControlDefaultUseWebXRElements, fileLoadDefaultLoadedGroup, fileLoadDefaultSelectedFile, fileLoadDefaultSelectedUrlExt, viewControlDefaultNeedsRecentring, viewControlDefaultEnableCursorTracking, viewControlDefaultHaveTrackedPoint, viewControlDefaultLastTrackedPoint, viewControlDefaultTargetTrackRadius, viewControlDefaultSpinAround} from "./WebGlDisplay";
import Pool from "./UserPool";
import Status from "./Status";

import { Link, useNavigate } from "react-router-dom";
import { Group, Vector3 } from "three";

function App() {
  const [gltfFile, setGltfFile] = useState('' as string)
  const [uploadedFile, setUploadedFile] = useState('' as string)

  const navigate = useNavigate()

  const viewControls = {} as ViewControlProps
  const fileLoadProps = {} as FileLoadProps

  [fileLoadProps.loadedGroup, fileLoadProps.setLoadedGroup] = useState(fileLoadDefaultLoadedGroup()) as [Group|undefined, Dispatch<SetStateAction<Group|undefined>>]
  [fileLoadProps.selectedFile, fileLoadProps.setSelectedFile] = useState(fileLoadDefaultSelectedFile()) as [string, React.Dispatch<React.SetStateAction<string>>]
  [fileLoadProps.selectedUrlExt, fileLoadProps.setSelectedUrlExt] = useState(fileLoadDefaultSelectedUrlExt()) as [string [], React.Dispatch<React.SetStateAction<string []>>]

  [viewControls.centre, viewControls.setCentre] = useState(viewControlDefaultCentre()) as [Vector3, React.Dispatch<React.SetStateAction<Vector3>>]
  [viewControls.position, viewControls.setPosition] = useState(viewControlDefaultPosition()) as [Vector3, React.Dispatch<React.SetStateAction<Vector3>>]
  [viewControls.up, viewControls.setUp] = useState(viewControlDefaultUp()) as [Vector3, React.Dispatch<React.SetStateAction<Vector3>>] 
  [viewControls.eyeLightPosition, viewControls.setEyeLightPosition] = useState(viewControlDefaultEyeLightPosition()) as [Vector3, React.Dispatch<React.SetStateAction<Vector3>>]
  [viewControls.skyLightPosition, viewControls.setSkyLightPosition] = useState(viewControlDefaultSkyLightPosition()) as [Vector3, React.Dispatch<React.SetStateAction<Vector3>>]
  [viewControls.fov, viewControls.setFov] = useState(viewControlDefaultFov()) as [number, React.Dispatch<React.SetStateAction<number>>]
  [viewControls.near, viewControls.setNear] = useState(viewControlDefaultNear()) as [number, React.Dispatch<React.SetStateAction<number>>]
  [viewControls.far, viewControls.setFar] = useState(viewControlDefaultFar()) as [number, React.Dispatch<React.SetStateAction<number>>]
  [viewControls.eyeLightIntensity, viewControls.setEyeLightIntensity] = useState(viewControlDefaultEyeLightIntensity()) as [number, React.Dispatch<React.SetStateAction<number>>]
  [viewControls.skyLightIntensity, viewControls.setSkyLightIntensity] = useState(viewControlDefaulSkyLightIntensity()) as [number, React.Dispatch<React.SetStateAction<number>>]
  [viewControls.needsRecentring, viewControls.setNeedsRecentring] = useState(viewControlDefaultNeedsRecentring()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
  [viewControls.haveTrackedPoint, viewControls.setHaveTrackedPoint] = useState(viewControlDefaultHaveTrackedPoint()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
  [viewControls.lastTrackedPoint, viewControls.setLastTrackedPoint] = useState(viewControlDefaultLastTrackedPoint()) as [Vector3, React.Dispatch<React.SetStateAction<Vector3>>]

  [viewControls.targetTrackRadius, viewControls.setTargetTrackRadius] = useState(viewControlDefaultTargetTrackRadius()) as [number, React.Dispatch<React.SetStateAction<number>>]
  [viewControls.enableCursorTracking, viewControls.setEnableCursorTracking] = useState(viewControlDefaultEnableCursorTracking()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
  [viewControls.drawLightHelpers, viewControls.setDrawLightHelpers] = useState(viewControlDefaultDrawLightHelpers()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
  [viewControls.drawFOVHelper, viewControls.setDrawFOVHelper] = useState(viewControlDefaultDrawFOVHelper()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
  [viewControls.useWebXRElements, viewControls.setUseWebXRElements] = useState(viewControlDefaultUseWebXRElements()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
  [viewControls.spinAround, viewControls.setSpinAround] = useState(viewControlDefaultSpinAround()) as [boolean, React.Dispatch<React.SetStateAction<boolean>>] 

  useEffect(() => {
    const user = Pool.getCurrentUser()
    if (!user) {
      navigate('/login')
    } else {
      fileLoadProps.setSelectedFile(gltfFile)
    }
  }, [])

  const collectSelectedFile = (clickedFile : string) => {
    if (clickedFile !== fileLoadProps.selectedFile) {
      fileLoadProps.setSelectedFile(clickedFile)
      fileLoadProps.setLoadedGroup(fileLoadDefaultLoadedGroup())
      fileLoadProps.setSelectedUrlExt(fileLoadDefaultSelectedUrlExt())
      setGltfFile(clickedFile)
    }
  }

  const newUploadedFile = (uploadedFile : string, key : string) => {
    setUploadedFile(uploadedFile)
    collectSelectedFile(key)
  }

  return (
      <div className="sideBar">
        <div className="filesBar">
          <Status />
          <FileList collectSelectedFile={collectSelectedFile} />
          <FileUpload newUploadedFile={newUploadedFile} />
        </div>
        <WebGlDisplay fileLoadProps={fileLoadProps} viewControls={viewControls} />
      </div>
  );
}

export default App;
