import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserPool from "./UserPool";

const Signup = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordCheck, setPasswordCheck] = useState("")
    const [signUpError, setSignUpError] = useState(false)
    const [signUpErrorMessage, setSignUpErrorMessage] = useState('')

    const navigate = useNavigate()

    const onSubmit = (event : ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (password === passwordCheck) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            UserPool.signUp(email, password, [], null, (err, data) => {
                if (err) {
                    console.log(err)
                    setSignUpError(true)
                    setSignUpErrorMessage(err.message)
                } else {
                    console.log(data)
                    navigate('/verifyAccount')    
                }
            })
        } else {
            console.log(password, passwordCheck)
            setSignUpError(true)
            setSignUpErrorMessage('Passwords do not match')
        }
    }

    const ErrorMessage = () => (
        <div className="errorMessage">
            { signUpErrorMessage }
        </div>
    )

    return (
        <div className="signUp">
            <form onSubmit={onSubmit}>
                <input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder='email *'
                ></input>
                <input
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder='password *'
                    type='password'
                ></input>
                <input
                    value={passwordCheck}
                    onChange={(event) => setPasswordCheck(event.target.value)}
                    placeholder='confirm password *'
                    type='password'
                ></input>
                <button className='submit' type="submit">Create Account</button>
            </form>
            { signUpError ? <ErrorMessage /> : null }
        </div>
    )
}

export default Signup