import React, { useContext, useEffect, useRef, useState } from 'react';

import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

import { AccountContext } from "./Account";
import Pool from './UserPool';
import IdentityPoolData from "./IdentityPool";

function UploadFileToS3({newUploadedFile} : any) {
    const { getSession } = useContext(AccountContext)
    const [ email, setEmail ] = useState('')
    const [ token, setToken ] = useState('')
    const [ userId, setUserId] = useState('')

    const idpdata = IdentityPoolData()
    const REGION = idpdata.region;
    const cognitoIdp = idpdata.cognitoIdp
    const idpId = idpdata.idpId

    useEffect(() => {
        const user = Pool.getCurrentUser()
        if (user) {
            getSession()
                .then((session : any) => {
                    setEmail(session.getIdToken().payload.email)
                    setUserId(session.getIdToken().payload.sub)
                    setToken(session.getIdToken().getJwtToken())
                })
                .catch((err : any) => {
                    console.log(err)
                })
        }
    }, [getSession])

    const s3Client = new S3Client({ 
        region: REGION,
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION}),
            identityPoolId: idpId ,
            logins: {
                [cognitoIdp] : token
            },
            userIdentifier: email
        })    
    });

    const [fileToUpload, setFileToUpload] = useState({} as File)
    const uploadButton = useRef({} as HTMLButtonElement)

    const handleFileUpload = (e : any) => {
        setFileToUpload(e.target.files[0])
    }

    const allowedFileExtensions = ['fbx', 'glb', 'gltf']

    const uploadFile = async (file : File) => {
        const ext  = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (allowedFileExtensions.includes(ext)) {
            const params = {
                Bucket: 'cadfixbatchcloudviewer',
                Body: file,
                Key: userId + "/" + file.name
            }
            try {
                uploadButton.current.innerHTML = 'Uploading'
                const data = await s3Client.send(new PutObjectCommand(params))
                newUploadedFile(file, params["Key"])
                console.log('Uploaded', data)         
                uploadButton.current.innerHTML = ' Upload '
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log("Not Allowed", ext)
        }
    }
    
    return (
        <div className='fileUpload'>
            <span className='titleCard'> Upload a new model file</span>
            <input className='uploadCard' type='file' onChange={handleFileUpload} />
            <button className='uploadCard' ref={uploadButton} onClick={() => uploadFile(fileToUpload)}> Upload </button>
        </div>
    );
}

export default UploadFileToS3
