type IdPData = {
    region : string;
    cognitoIdp : string;
    idpId : string
}

function IdentityPoolData() : IdPData {
    const IdPool = {
        region: 'eu-west-3',
        cognitoIdp: `cognito-idp.eu-west-3.amazonaws.com/eu-west-3_DP2ZMYFVO`,
        idpId: 'eu-west-3:c93c767c-06b3-4d96-8b5e-60371365c6e6'
    }
    return IdPool
}

export default IdentityPoolData