import React, { Dispatch, ReactElement, SetStateAction, Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useProgress, Html, useFBX, useGLTF, GizmoHelper, GizmoViewport, TrackballControls, Bounds, useBounds, useHelper, Sphere} from '@react-three/drei'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { VRButton, XR, Controllers, Hands} from '@react-three/xr';
import THREE, { Group, Object3D, Box3, Vector3, Ray, MathUtils, DirectionalLight, PerspectiveCamera, DirectionalLightHelper, SpotLight, SpotLightHelper, ColorRepresentation, SphereGeometry, BufferAttribute } from 'three';
import { Controls, ControlsProvider, useControl, ControlOptions  } from 'react-three-gui';

import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

import Pool from "./UserPool"
import IdentityPoolData from "./IdentityPool";

export interface FileLoadProps {
  loadedGroup : Group|undefined
  selectedFile : string
  selectedUrlExt : string[]

  setLoadedGroup : Dispatch<SetStateAction<Group|undefined>>
  setSelectedFile : React.Dispatch<React.SetStateAction<string>>
  setSelectedUrlExt : React.Dispatch<React.SetStateAction<string []>>
}

export interface ViewControlProps {
  centre : Vector3
  position : Vector3
  up : Vector3
  eyeLightPosition : Vector3
  skyLightPosition : Vector3
  fov : number
  near : number
  far : number
  eyeLightIntensity : number
  skyLightIntensity : number
  needsRecentring : boolean
  haveTrackedPoint : boolean
  lastTrackedPoint : Vector3

  targetTrackRadius : number
  enableCursorTracking : boolean
  drawLightHelpers : boolean
  drawFOVHelper : boolean
  useWebXRElements : boolean
  spinAround : boolean

  setCentre : React.Dispatch<React.SetStateAction<Vector3>>
  setPosition : React.Dispatch<React.SetStateAction<Vector3>>
  setUp : React.Dispatch<React.SetStateAction<Vector3>>
  setEyeLightPosition : React.Dispatch<React.SetStateAction<Vector3>>
  setSkyLightPosition : React.Dispatch<React.SetStateAction<Vector3>>
  setFov : React.Dispatch<React.SetStateAction<number>>
  setNear : React.Dispatch<React.SetStateAction<number>>
  setFar : React.Dispatch<React.SetStateAction<number>>
  setEyeLightIntensity : React.Dispatch<React.SetStateAction<number>>
  setSkyLightIntensity : React.Dispatch<React.SetStateAction<number>>
  setNeedsRecentring : React.Dispatch<React.SetStateAction<boolean>>
  setHaveTrackedPoint : React.Dispatch<React.SetStateAction<boolean>>
  setLastTrackedPoint : React.Dispatch<React.SetStateAction<Vector3>>

  setTargetTrackRadius : React.Dispatch<React.SetStateAction<number>>
  setEnableCursorTracking : React.Dispatch<React.SetStateAction<boolean>>
  setDrawLightHelpers : React.Dispatch<React.SetStateAction<boolean>>
  setDrawFOVHelper : React.Dispatch<React.SetStateAction<boolean>>
  setUseWebXRElements : React.Dispatch<React.SetStateAction<boolean>>
  setSpinAround : React.Dispatch<React.SetStateAction<boolean>>
}

export function viewControlDefaultCentre() : Vector3 { return new Vector3(0,0,0) }
export function viewControlDefaultPosition() : Vector3 {  return new Vector3(0,0,0) }
export function viewControlDefaultUp() : Vector3 {  return new Vector3(0,0,1) }
export function viewControlDefaultEyeLightPosition() : Vector3 {  return new Vector3(0.000,0.000,2.0) }
export function viewControlDefaultSkyLightPosition() : Vector3 {  return new Vector3(0, 20, -0.1) }
export function viewControlDefaultFov() : number { return 86 }
export function viewControlDefaultNear() : number { return 0.01 }
export function viewControlDefaultFar() : number { return 10000 }
export function viewControlDefaultEyeLightIntensity() : number { return 0.4 }
export function viewControlDefaulSkyLightIntensity() : number { return 0.8 }
export function viewControlDefaultNeedsRecentring() : boolean { return false }
export function viewControlDefaultHaveTrackedPoint() : boolean { return false }
export function viewControlDefaultLastTrackedPoint() : Vector3 { return new Vector3(0,0,0)}

export function viewControlDefaultTargetTrackRadius() : number { return 0.5 }
export function viewControlDefaultEnableCursorTracking() : boolean { return true }
export function viewControlDefaultDrawLightHelpers() : boolean { return false }
export function viewControlDefaultDrawFOVHelper() : boolean {  return false }
export function viewControlDefaultUseWebXRElements() : boolean {  return false }
export function viewControlDefaultSpinAround() : boolean {  return false }

function viewControlSetAllDefaults(viewControl : ViewControlProps ) : void {
  viewControl.setCentre(viewControlDefaultCentre())
  viewControl.setPosition(viewControlDefaultPosition())
  viewControl.setUp(viewControlDefaultUp())
  viewControl.setEyeLightPosition(viewControlDefaultEyeLightPosition())
  viewControl.setSkyLightPosition(viewControlDefaultSkyLightPosition())
  viewControl.setFov(viewControlDefaultFov())
  viewControl.setNear(viewControlDefaultNear())
  viewControl.setFar(viewControlDefaultFar())
  viewControl.setEyeLightIntensity(viewControlDefaultEyeLightIntensity())
  viewControl.setSkyLightIntensity(viewControlDefaulSkyLightIntensity())
  viewControl.setNeedsRecentring(viewControlDefaultNeedsRecentring())
  viewControl.setHaveTrackedPoint(viewControlDefaultHaveTrackedPoint())
  viewControl.setLastTrackedPoint(viewControlDefaultLastTrackedPoint())

  viewControl.setTargetTrackRadius(viewControlDefaultTargetTrackRadius())
  viewControl.setEnableCursorTracking(viewControlDefaultEnableCursorTracking())
  viewControl.setDrawLightHelpers(viewControlDefaultDrawLightHelpers())
  viewControl.setDrawFOVHelper(viewControlDefaultDrawFOVHelper())
  viewControl.setUseWebXRElements(viewControlDefaultUseWebXRElements())
  viewControl.setSpinAround(viewControlDefaultSpinAround())
}

export function fileLoadDefaultLoadedGroup() : Group|undefined { return undefined }
export function fileLoadDefaultSelectedFile() : string { return ('') }
export function fileLoadDefaultSelectedUrlExt() : string[] { return (['','']) }

function fileLoadedSetAllDefaults(fileLoadedProps : FileLoadProps) : void {
  fileLoadedProps.setLoadedGroup(fileLoadDefaultLoadedGroup())
  fileLoadedProps.setSelectedFile(fileLoadDefaultSelectedFile())
  fileLoadedProps.setSelectedUrlExt(fileLoadDefaultSelectedUrlExt())
}

function Loader() {
  const { progress } = useProgress()
  return <Html center> Loading ... { progress }% </Html>
}

function getViewWidth(box : Box3, eyeRay : Ray) : [number, Vector3] {

  let closestPoint = new Vector3()
  box.getCenter(closestPoint)
  //eyeRay.intersectBox(box, closestPoint)
  let point = new Vector3()
  let target = new Vector3()
  let currentDist = 0.0
  let maxRadius = 0.0 as number
  let minDist = eyeRay.distanceSqToPoint(closestPoint)

  for (let x of [box.min.x, box.max.x]) {
    for(let y of [box.min.y, box.max.y]) {
      for(let z of [box.min.z, box.max.z]) {
        [point.x, point.y, point.z] = [x, y, z]
        maxRadius = Math.max(maxRadius, eyeRay.distanceSqToPoint(point))
        eyeRay.closestPointToPoint(point, target)
        currentDist = eyeRay.distanceSqToPoint(target)
        if (currentDist < minDist) {
          minDist = currentDist
          closestPoint = target.clone()
        }
        
      }
    }
  }

  return [Math.sqrt(maxRadius), closestPoint]
}

function getWorldBox(group : Group, scaleFactor : number, bbox : Box3) : Box3 {
  bbox.setFromObject(group).expandByScalar(scaleFactor);
  return bbox;
}

function GltfFile(url : string) : Group {
  const { scene } = useGLTF(url)
  const copiedScene = useMemo(() => scene.clone(), [scene])
  return copiedScene;
}

function FbxFile(url : string) : Group {
  const scene = useFBX(url);
  const copiedScene = useMemo(() => scene.clone(), [scene])
  return copiedScene;
}

function SelectedFile(urlExt : string[]) : Group {
  if (urlExt[1] === 'fbx') {
    return FbxFile(urlExt[0])
  } else {
    return GltfFile(urlExt[0])
  }
}

function WebGlView({fileLoadProps, viewControls} : 
  {fileLoadProps: FileLoadProps, viewControls : ViewControlProps})  : ReactElement<any> {

    fileLoadProps.setLoadedGroup(SelectedFile(fileLoadProps.selectedUrlExt))

    if (fileLoadProps.loadedGroup !== undefined)
    {
      return <primitive object={fileLoadProps.loadedGroup} />
    }
    else
    {
      return <></>
    }
}

function WebGLSceneSuspense({fileLoadProps, viewControls} : 
  {fileLoadProps: FileLoadProps, viewControls : ViewControlProps}) : ReactElement<any> {

    return (
        <Suspense fallback={<Loader />}>
          <WebGlView fileLoadProps={fileLoadProps} viewControls={viewControls}/>
        </Suspense>
      );
}

function WebGLScene({fileLoadProps, viewControls} : 
  {fileLoadProps: FileLoadProps, viewControls : ViewControlProps}) : ReactElement<any> {
  return (
    <XR>
      <Controllers />
      <Hands />
      <WebGLSceneSuspense fileLoadProps={fileLoadProps} viewControls={viewControls}/>
      <GizmoHelper
        alignment="bottom-right" // widget alignment within scene
        margin={[80, 80]} // widget margins (X, Y)
        >
        <GizmoViewport axisColors={['red', 'green', 'blue']} labelColor="black" />
      </GizmoHelper>

    </XR> );
}

function DirectionalLightHelperWrapper({lightRef, color} : {lightRef:React.RefObject<THREE.DirectionalLight>, color:ColorRepresentation}) : ReactElement<any>
{
  // @ts-ignore
  useHelper(lightRef, DirectionalLightHelper, 1, color)
  return <></>
}

function SpotLightHelperWrapper({lightRef, color} : {lightRef:React.RefObject<THREE.SpotLight>, color:ColorRepresentation}) : ReactElement<any>
{
  // @ts-ignore
  useHelper(lightRef, SpotLightHelper, color)
  return <></>
}

function CameraAndLights({fileLoadProps, viewControls} : 
  {fileLoadProps: FileLoadProps, viewControls : ViewControlProps}) : ReactElement<any> 
{

  const threeContext = useThree()
  const bounds = useBounds()

  const eyeLightRef = useRef<DirectionalLight>(null)
  const skyLightRef = useRef<SpotLight>(null)
  const cameraRef = useRef<PerspectiveCamera>(null)
  const eyeTargetRef = useRef<Object3D>(null)
  const skyTargetRef = useRef<Object3D>(null)

  const boxScale = 1.5
  let bbox : Box3 = new Box3()

  //console.log("beep")
  // we are using a manually defined camera, so we are forced to set
  // it as the default camera in the three context.
  //I'm not sure what happens if we have multiple cameras.
  useLayoutEffect(() => {
    if(cameraRef && cameraRef.current) 
    {
      threeContext.set({camera: cameraRef.current})
      //console.log("set default camera")
    }
  }, [cameraRef])

  // reset the view controls if we are loading a fresh file
  useEffect(() => 
  {
    viewControlSetAllDefaults(viewControls)
    //console.log("reset all controls")

  },[fileLoadProps.loadedGroup]) 

  //fileLoadProps.loadedGroup.addEventListener()

  //recalculate clipping if the loaded group changes
  useEffect(() => {
    if(cameraRef && cameraRef.current && fileLoadProps.loadedGroup !== undefined) 
    {
      bounds.refresh().clip()
      //console.log("reset clip")
      //bounds.to({position:viewControls.position.toArray(), target:viewControls.centre.toArray()})
    }
  }, [cameraRef, fileLoadProps.loadedGroup])

  //recalculate rotation centre and camera position if model has changed
  useEffect(() => 
  {
    if(fileLoadProps.loadedGroup !== undefined)
    {
      //console.log("recalculate centres 1 ")
      //get scene bounding box scaled by target value
      getWorldBox(fileLoadProps.loadedGroup, boxScale, bbox)
      //const bbox = bounds.refresh().getSize().box.expandByScalar(boxScale)
  
      //set up a ray representing the camera. it starts at the box centroid pointing along the target eye vector and then
      //we move its origin to be outside the box back long the eye vector. 
      const centroid = new Vector3()
      const boxSize = new Vector3()
      bbox.getCenter(centroid)
      bbox.getSize(boxSize)
      const eyeRay = new Ray(centroid.clone(), new Vector3(-0.3, -0.3, -0.3).normalize())
      eyeRay.origin.addScaledVector(eyeRay.direction, -boxSize.length()*2.0)

      // figure out how big the bounding box is when viewed along the eye vector and move the eye ray so that
      // the whole box can be seen. 
      const [maxDist, closestPoint] = getViewWidth(bbox, eyeRay)
      const eyeDistance = maxDist / Math.tan(MathUtils.degToRad(viewControls.fov/2))
      closestPoint.addScaledVector(eyeRay.direction, -eyeDistance)
      
      // update state with our new centre and camera positoin
      viewControls.setCentre(centroid.clone())
      viewControls.setPosition(closestPoint.clone())

      viewControls.setNeedsRecentring(true)
    }
  }, [fileLoadProps.loadedGroup]);

  // if the centre of rotation changes force update the world matrices for the eye and sky lights
  // because it doesnt seem to do this automatically due to a bug.
  useEffect(() => {
    if(eyeLightRef && eyeLightRef.current && eyeTargetRef && eyeTargetRef.current) 
    {
      //console.log("update eye track")
      eyeLightRef.current.target = eyeTargetRef.current
      eyeLightRef.current.target.updateMatrixWorld()
    }
  }, [eyeLightRef, eyeTargetRef, viewControls.centre])

  useEffect(() => {
    if(skyLightRef && skyLightRef.current && skyTargetRef && skyTargetRef.current) 
    {
      //console.log("update sky track")
      skyLightRef.current.target = skyTargetRef.current
      skyLightRef.current.target.updateMatrixWorld()
    }
  }, [skyLightRef, skyTargetRef, viewControls.centre])

  // update where the camera is pointing when we have decided on target information
  useEffect(() => {
    if(cameraRef && cameraRef.current && fileLoadProps.loadedGroup !== undefined && viewControls.needsRecentring) 
    {
      //console.log("move camera to", viewControls.position, viewControls.centre )
      bounds.to({position:viewControls.position.toArray(), target:viewControls.centre.toArray()})
      bounds.refresh().fit().clip()
      viewControls.setPosition(cameraRef.current.position.clone())
      viewControls.setNeedsRecentring(false)
    }
  }, [cameraRef, fileLoadProps.loadedGroup, viewControls.centre, viewControls.position, viewControls.needsRecentring])


  //update actions to take per frame
  useFrame((state, delta) =>
  {
    if(cameraRef && cameraRef.current && !viewControls.needsRecentring && viewControls.spinAround) 
    {
      const vectorFromCentre = new Vector3()
      vectorFromCentre.subVectors(viewControls.position, viewControls.centre)
      vectorFromCentre.applyAxisAngle(viewControls.up, MathUtils.degToRad(0.01/delta))
      vectorFromCentre.addVectors(viewControls.centre, vectorFromCentre)
      viewControls.setPosition(vectorFromCentre)
      //console.log(delta)
    }
    
  })

/*
  useEffect(() => 
  {

  }, [])
  */

  return (
    <>
      <perspectiveCamera ref={cameraRef} castShadow fov={viewControls.fov} position={viewControls.position} zoom={1.0} up={viewControls.up} near={viewControls.near} far={viewControls.far} onUpdate={self => self.lookAt(viewControls.centre)}> 
        <directionalLight  ref={eyeLightRef} castShadow color={0xffffff} position={viewControls.eyeLightPosition} intensity={viewControls.eyeLightIntensity}/>
        <spotLight ref={skyLightRef} castShadow color={0xffffff} position={viewControls.skyLightPosition} intensity={viewControls.skyLightIntensity}/>
      </perspectiveCamera>
      <object3D ref={eyeTargetRef} position={viewControls.centre} />
      <object3D ref={skyTargetRef} position={viewControls.centre} />
      {viewControls.drawFOVHelper && fileLoadProps.loadedGroup !== undefined && <box3Helper box={getWorldBox(fileLoadProps.loadedGroup, boxScale, bbox)} userData={{color:'black'}}/>}
      {viewControls.drawLightHelpers && <DirectionalLightHelperWrapper lightRef={eyeLightRef} color='red'/>}
      {viewControls.drawLightHelpers && <SpotLightHelperWrapper lightRef={skyLightRef} color='cyan'/>}
    </>
    );
}

function MouseTracking({fileLoadProps, viewControls} : 
  {fileLoadProps: FileLoadProps, viewControls : ViewControlProps}) : ReactElement<any> {

  const threeContext = useThree()

  const [radius, setRadius] = useState(0.1)
  const tanTargetAngle = Math.tan(MathUtils.degToRad(viewControls.targetTrackRadius))

  useFrame((state, delta) =>
  {
    if(fileLoadProps.loadedGroup) {
      const objects = threeContext.raycaster.intersectObjects([fileLoadProps.loadedGroup])
      if (objects.length > 0) {
        viewControls.setLastTrackedPoint(objects[0].point.clone())
        setRadius(objects[0].distance*tanTargetAngle)
        viewControls.setHaveTrackedPoint(true)
        //console.log(objects)
      } else {
        viewControls.setHaveTrackedPoint(false)
      }
    } else {
      viewControls.setHaveTrackedPoint(false)
    }
  })

  return <>
      {viewControls.enableCursorTracking && viewControls.haveTrackedPoint && 
      <mesh position={viewControls.lastTrackedPoint}  onDoubleClick={(e) => viewControls.setCentre(viewControls.lastTrackedPoint.clone())} >
        <sphereGeometry args={[radius, 5, 5]} />
        <meshStandardMaterial color={"blue"} />
      </mesh>}
    </>

}

function SettingsList({viewControls} : 
  {viewControls : ViewControlProps}) : ReactElement<any> {

    const userFov = useControl('FOV', {type: "number", min: 1, max:180, state: [viewControls.fov, viewControls.setFov] });
    const userNear = useControl('Near', {type: "number", min: 0.0, max:10000, state: [viewControls.near, viewControls.setNear] });
    const userFar = useControl('Far', {type: "number", min: 0.0, max:10000, state: [viewControls.far, viewControls.setFar] });
    const userNeedsRecentring = useControl('Recentre', {type: "boolean", state: [viewControls.needsRecentring, viewControls.setNeedsRecentring] });
    const userEnableCursorTracking = useControl('Track cursor', {type: "boolean", state: [viewControls.enableCursorTracking, viewControls.setEnableCursorTracking] });
    const userTrackBallRadius = useControl('Track ball size', {type: "number", min: 0.0, max:10, state: [viewControls.targetTrackRadius, viewControls.setTargetTrackRadius] });
    const userDrawLightHelpers = useControl('Light Helpers', {type: "boolean", state: [viewControls.drawLightHelpers, viewControls.setDrawLightHelpers] });
    const userDrawFOVHelper = useControl('Object Boxes', {type: "boolean", state: [viewControls.drawFOVHelper, viewControls.setDrawFOVHelper] });
    const userUseWebXRElements = useControl('Web XR', {type: "boolean", state: [viewControls.useWebXRElements, viewControls.setUseWebXRElements] });
    const userSpinAround = useControl('Spin', {type: "boolean", state: [viewControls.spinAround, viewControls.setSpinAround] });

    return <></>
}

export function WebGlDisplay({fileLoadProps, viewControls} : 
  {fileLoadProps: FileLoadProps, viewControls : ViewControlProps}) : ReactElement<any> {

  useEffect(() => {
    GeneratePreSignedUrl(fileLoadProps.selectedFile)
  }, [fileLoadProps.selectedFile])

  async function GeneratePreSignedUrl(selectedFile : string) {
    const bucketParams = {
      Bucket: "cadfixbatchcloudviewer",
      Key: selectedFile
    }
    const idpdata = IdentityPoolData()
    const REGION = idpdata.region;
    const cognitoIdp = idpdata.cognitoIdp
    const idpId = idpdata.idpId
    let s3Client = new S3Client({})
    let params = {} as any
    let ext = ""
    const user = Pool.getCurrentUser()
    if (user) {
        user.getSession(function(err : any, session : any) {
            if (err) {
                console.log(err)
            }
            s3Client = new S3Client({ 
                region: REGION,
                credentials: fromCognitoIdentityPool({
                    client: new CognitoIdentityClient({ region: REGION}),
                    identityPoolId: idpId,
                    logins: {
                        [cognitoIdp] : session.getIdToken().getJwtToken()
                    },
                    userIdentifier: session.getIdToken().payload.email
                })    
            });
            params = bucketParams           
        })
        const command = new GetObjectCommand(params)
        const data = await getSignedUrl(s3Client, command, {
          expiresIn: 3600,
        } );
        if (selectedFile) {
          ext = selectedFile.substring(selectedFile.lastIndexOf('.') + 1)
        }
        fileLoadProps.setSelectedUrlExt([data, ext])
    }
  }

  if (fileLoadProps.selectedUrlExt[0]?.length > 1) {
    return (
   
    <div className='webGL'>
    {viewControls.useWebXRElements && <VRButton />}
    <ControlsProvider>
      <Canvas shadows>
        <Bounds clip damping={20} margin={1.2}>
          <CameraAndLights fileLoadProps={fileLoadProps} viewControls={viewControls}/>
          <WebGLScene fileLoadProps={fileLoadProps} viewControls={viewControls}/>
        </Bounds>
        <MouseTracking fileLoadProps={fileLoadProps} viewControls={viewControls}/>
        <TrackballControls target={viewControls.centre} />
      </Canvas>
      <SettingsList viewControls={viewControls}/>
      <Controls title="settings" collapsed={false} />
    </ControlsProvider>
    </div>);
    
  } else {
    return (
      <div className='webGL'></div>
    )
  }
}

export default WebGlDisplay;

