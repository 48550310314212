import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

import Pool from "./UserPool";

interface AccountContextType {
    authenticate : any,
    getSession : any,
    logout : any
}

const AccountContext = createContext<AccountContextType>({} as AccountContextType)

const Account = (props : any) => {
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser()
            if (user) {
                user.getSession((err : any, session : any) => {
                    if (err) {
                        reject()
                    } else {
                        resolve(session)
                    }
                })
            } else {
                reject()
            }
        })
    }

    const authenticate = async (Username : string, Password : string) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser ({ Username, Pool })
            const authDetails = new AuthenticationDetails({ Username, Password })

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    resolve(data)
                },
                onFailure: (data) => {
                    console.log("onFailure: ", data.name)
                    reject(data)
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired", data)
                    resolve(data)
                }
            })
        })
    }

    const logout = () => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.signOut()
        }
    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout }}>
            { props.children }
        </AccountContext.Provider>
    )
}

export { Account, AccountContext }
