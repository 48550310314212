import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

function ErrorPage() {
    const error = useRouteError()
    console.error(error)

    if (isRouteErrorResponse(error)) {
        return (
            <div id='errorPage'>
                <h1>Oops!</h1>
                <p>
                    <i>{ error.status } { error.statusText }</i>
                </p>
            </div>
        )    
    }
    return (
        <div id='errorPage'>
            <h1>Oops!</h1>
            <p>
                <i>"Unknown Error"</i>
            </p>
        </div>
    )
}

export default ErrorPage