import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import Login from "./Login";
import Logout from "./Logout";

const Status = () => {
    const [status, setStatus] = useState(false)
    const { getSession } = useContext(AccountContext)

    useEffect(() => {
        getSession()
            .then((session : any) => {
            setStatus(true)
            })
            .catch((err : any) => {
                console.log(err)
                setStatus(false)
            })
    }, [])

    return <div>{status ? <Logout /> : <Login />}</div>
}
export default Status