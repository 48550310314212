import React, { ChangeEvent, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";

import UserPool from "./UserPool";

const VerifyAccount = () => {
    const [email, setEmail] = useState("")
    const [code, setCode ] = useState("")
    const [errorMessage, setErrorMessage] = useState(false)

    const navigate = useNavigate()

    const verifyAccount = (event : ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        const user = new CognitoUser ({
            Username: email,
            Pool: UserPool
        })

        user.confirmRegistration(code, true, (err, data) => {
            if (err) {
                console.log(err)
                setErrorMessage(true)
            } else {
                console.log(data)
                navigate('/')
            }
        })
    }

    const ErrorMessage = () => (
        <div className="errorMessage">
            Incorrect Verification Code
        </div>
    )

    return (
        <div className="signUp">
            <span>Verification code sent. Check your inbox.</span>
            <form onSubmit={verifyAccount}>
                <input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder='email'
                ></input>
                <input
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    placeholder='code'
                ></input>
                <button className='submit' type="submit">Verify Account</button>
            </form>
            { errorMessage ? <ErrorMessage /> : null }
        </div>
    )
}

export default VerifyAccount