import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "./Account";
import Pool from "./UserPool";

import { useNavigate } from "react-router-dom";

const Logout = () => {
    const { logout, getSession } = useContext(AccountContext)
    const [email, setEmail] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        const user = Pool.getCurrentUser()
        if (user) {
            getSession()
                .then((session : any) => {
                    setEmail(session.getIdToken().payload.email)
                })
                .catch((err : any) => {
                    console.log(err)
                })
        }
    }, [getSession])

    const logOutClick = () => {
        logout()
        navigate("/login")
    }

    return (
        <div className="fileList">
            <div className="fileCard">
                Logged in as : {email}
            </div>            
            <button className="uploadCard" onClick={logOutClick}>Logout</button>
        </div>
    )
}

export default Logout