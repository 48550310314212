import React, { useState, useContext, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from "./Account";

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [authenticated, setAuthenticated] = useState()
    const { authenticate } = useContext(AccountContext)

    const [errorMessage, setErrorMessage] = useState(false)
    const navigate = useNavigate()

    const onSubmit = (event : ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        authenticate(email, password)
            .then((data : any) => {
                setAuthenticated(data)
                navigate('/')
            })
            .catch((err : any) => {
                console.error("failed to log in", err)
                setErrorMessage(true)
            })
        setErrorMessage(false)
    }

    const ErrorMessage = () => (
        <div className="errorMessage">
            Incorrect Username or Password
        </div>
    )

    return (
        <div className="signUp">
            <form onSubmit={onSubmit}>
                <input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder='email'
                ></input>
                <input
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder='password'
                    type='password'
                ></input>
                { errorMessage ? <ErrorMessage /> : null }
                <button className='submit' type="submit">Login</button>
            </form>
            <div className="signUpLink"> If you don't have an account:
            <Link to="/createAccount">
                <button className='createAccount' >Sign Up</button>
            </Link>
            </div>
        </div>
    )
}

export default Login