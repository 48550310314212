import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';

import { Account } from './Account'
import App from './App';
import ErrorPage from './RouterError'
import Signup from './SignUp';
import Status from './Status';
import VerifyAccount from './verifyAccount';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "/login",
    element: <Status />,
    errorElement: <ErrorPage />
  },
  {
    path: "/createAccount",
    element: <Signup />
  },
  {
    path: "/verifyAccount",
    element: <VerifyAccount />
  }
])
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <div>
    <React.StrictMode>
    <Account>
    <div className='content'>
      <div className='titleBar'>
        CADfix File Viewer
      </div>
      <RouterProvider router={router} />
    </div>
    </Account>
    </React.StrictMode>
  </div>
);

