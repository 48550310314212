import React, { useEffect, useState } from "react"

import { ListObjectsCommand, S3Client } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

import Pool from "./UserPool"
import IdentityPoolData from "./IdentityPool";

type S3File = {
    Key? : string | undefined,
    LastModified? : Date | undefined,
    ETag? : string | undefined,
    Size? : number | undefined,
    StorageClass? : string | undefined,
}

function FileList({collectSelectedFile} : any) {
    const [fileList, setFileList] = useState<S3File[]>([])
    const idpdata = IdentityPoolData()

    const SECOND_MS = 1000;
    const REGION = idpdata.region;
    const cognitoIdp = idpdata.cognitoIdp
    const idpId = idpdata.idpId
    let s3Client = new S3Client({})
    let params = {} as any
  
    async function fetchFiles() {
        const user = Pool.getCurrentUser()
        if (user) {
            user.getSession(function(err : any, session : any) {
                if (err) {
                    console.log(err)
                }
                s3Client = new S3Client({ 
                    region: REGION,
                    credentials: fromCognitoIdentityPool({
                        client: new CognitoIdentityClient({ region: REGION}),
                        identityPoolId: idpId,
                        logins: {
                            [cognitoIdp] : session.getIdToken().getJwtToken()
                        },
                        userIdentifier: session.getIdToken().payload.email
                    })    
                });

                params = {
                    Bucket: 'cadfixbatchcloudviewer',
                    Prefix: session.getIdToken().payload.sub
                }            
            })
            const data = await s3Client.send(new ListObjectsCommand(params));
            if (data.Contents) {
                const newFileList = data.Contents.length
                if (fileList.length !== newFileList) {
                    setFileList(data.Contents)
                }
            }
        }
    }    

    useEffect(() => {
        fetchFiles()
    }, [])

    return (
        <div className='fileList'>
            <span className='titleCard'>Your Files</span>
            {fileList?.map((file) => (
                <button className='fileCard' key={file.Key} onClick={() => collectSelectedFile(file.Key)}>{file.Key?.split("/")[1]}</button>
            ))}
        </div>
    );
}

export default FileList
